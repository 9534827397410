import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  type SanityCombinedListingProduct,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { LanguageContext } from '@lib/language-context'
import { useVariantMetadata } from '@lib/metadata'
import { useProductWithInventory } from './inventory'
import { useActiveVariant } from './variant'

interface ProductContextProps {
  product?: SanityCombinedListingProduct
  activeVariant?: SanityProductVariantFragment
  onVariantChange: (variantId: number) => void
}

interface ProductContextProviderProps {
  product?: SanityCombinedListingProduct
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({
  onVariantChange: () => null,
})

export const ProductContextProvider = ({
  product: originalProduct,
  children,
}: ProductContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityCombinedListingProduct>()

  // Active product
  const activeProduct = useMemo(
    () => product ?? originalProduct,
    [originalProduct, product]
  )
  useEffect(() => {
    setProduct(originalProduct)
  }, [originalProduct])

  // Get active product and variant
  const productWithInventory = useProductWithInventory(locale, activeProduct)
  const [activeVariant, onVariantChange] =
    useActiveVariant(productWithInventory)

  // Set product variant metadata
  useVariantMetadata(activeVariant)

  return (
    <ProductContext.Provider
      value={{
        product: productWithInventory,
        activeVariant,
        onVariantChange,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
